import { create } from "zustand";

interface CustomerState {
	email: string | null;
	setCustomerEmail: (email: string) => void;
	clear: () => void;
}

export const useCustomer = create<CustomerState>()((set) => ({
	email: null,
	setCustomerEmail: (email) => set({ email }),
	clear: () => set({ email: null }),
}));
