import {
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Transition,
} from "@headlessui/react";
import { Fragment, useState } from "react";
import { ChevronIcon } from "../../assets/chevron";
import { cn } from "../../lib/cn";
import { norm } from "../../lib/norm";
import { Button } from "./button";

type Option = {
	value: string;
	label: string;
	alias?: string[];
};

type Dropdown<T extends Option> = {
	options: T[];
	defaultSelected?: T["value"];
	onChange?: (value: T) => void;
	buttonProps?: React.ComponentProps<typeof Button>;
	render?: (option: T) => React.ReactNode;
};

export function Dropdown<T extends Option>({
	options,
	defaultSelected,
	onChange,
	buttonProps,
	render,
}: Dropdown<T>) {
	const [query, setQuery] = useState("");
	const [selected, setSelected] = useState<T>(
		options.find((v) => v.value === defaultSelected) || options[0],
	);

	return (
		<Menu as="div" className="relative">
			{({ open }) => (
				<>
					<MenuButton
						as="div"
						className="flex w-min cursor-pointer items-center space-x-2"
					>
						<Button
							icon={
								<ChevronIcon
									className={cn(
										"w-3 transition-all",
										open ? "rotate-180" : "rotate-0",
									)}
								/>
							}
							iconPosition="right"
							{...buttonProps}
						>
							{selected.label}
						</Button>
					</MenuButton>
					<Transition
						as={Fragment}
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0"
					>
						<MenuItems className="absolute max-h-72 overflow-auto right-0 z-30 mt-1 flex w-[calc(100vw_-_2rem)] xs:w-48 sm:w-60 origin-top-left flex-col divide-y divide-neutral-300 rounded-md rounded-b-lg border border-y border-neutral-300 bg-white shadow-lg">
							<div className="flex w-full flex-col space-y-1 px-4 py-2">
								<input
									type="text"
									placeholder="Search..."
									className="outline-none text-sm"
									value={query}
									onChange={(e) => setQuery(e.target.value)}
								/>
							</div>
							<div className="flex w-full flex-col space-y-1 px-1 py-1">
								{options
									.filter(
										({ label, alias }) =>
											norm(label)
												.toLowerCase()
												.includes(norm(query).toLowerCase()) ||
											alias?.some((a) =>
												norm(a)
													.toLowerCase()
													.includes(norm(query).toLowerCase()),
											),
									)
									.map((option) => (
										<MenuItem
											key={option.value}
											as="button"
											className="rounded-md hover:bg-neutral-100 focus:bg-neutral-100"
											onClick={() => {
												setSelected(option);
												onChange?.(option);
											}}
										>
											{render ? (
												render(option)
											) : (
												<span className="block px-3 py-1 text-left text-sm text-neutral-800">
													{option.label}
												</span>
											)}
										</MenuItem>
									))}
							</div>
						</MenuItems>
					</Transition>
				</>
			)}
		</Menu>
	);
}
