import { z } from "zod";

export const bankSchema = z.object({
	id: z.string(),
	name: z.string(),
	logo: z.string(),
	currencies: z.array(z.string()),
	market: z.array(z.string()),
	test: z.boolean(),
});
