export function hexToRgb(color: string, alpha = 1) {
	const cleanColor = color.startsWith("#") ? color.slice(1) : color;

	const r = cleanColor.charAt(0) + cleanColor.charAt(1);
	const g = cleanColor.charAt(2) + cleanColor.charAt(3);
	const b = cleanColor.charAt(4) + cleanColor.charAt(5);

	return `rgb(${Number.parseInt(r, 16)}, ${Number.parseInt(
		g,
		16,
	)}, ${Number.parseInt(b, 16)}, ${alpha})`;
}
