import { createRoute, useLoaderData } from "@tanstack/react-router";
import { z } from "zod";
import { Loading } from "../components/loading";
import { Message } from "../components/message";
import { fetcher } from "../lib/fetcher";
import { rootRoute } from "./__root";

export const linkSchema = z.object({
	checkoutSessionUrl: z.string(),
});

export const linkRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/link/$linkId",
	loader: async ({ params }) => {
		const link = await fetcher(
			`${import.meta.env.VITE_API_URL}/checkout/session/${params.linkId}`,
			linkSchema,
		);

		return link;
	},
	component: LinkPage,
	errorComponent: () => (
		<Message
			title="Something went wrong"
			content="The page you were looking for could not be found. Please check the URL or contact the merchant."
		/>
	),
});

function LinkPage() {
	const { checkoutSessionUrl } = useLoaderData({
		from: "/link/$linkId",
	});

	if (checkoutSessionUrl) {
		window.location.replace(checkoutSessionUrl);
	}

	return <Loading className="w-screen h-screen" />;
}
