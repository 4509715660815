export const bankLocales = [
	{
		value: "AT",
		label: "Österreich",
		alias: ["Austria", "Österrike", "Oostenrijk"],
	},
	{ value: "BE", label: "België", alias: ["Belgium", "Belgie", "Belgique"] },
	{ value: "DK", label: "Danmark", alias: ["Denmark", "Danmark", "Dänemark"] },
	// { value: "EE", label: "Eesti", alias: ["Estonia", "Estland"] },
	{ value: "FI", label: "Suomi", alias: ["Finland", "Finlandia", "Finnland"] },
	{ value: "FR", label: "France", alias: ["France", "Frankreich", "Francia"] },
	{
		value: "DE",
		label: "Deutschland",
		alias: ["Germany", "Alemania", "Allemagne"],
	},
	// { value: "HU", label: "Magyarország", alias: ["Hungary", "Ungarn", "Hongrie"] },
	{ value: "IE", label: "Ireland", alias: ["Ireland", "Irland", "Irlanda"] },
	{ value: "IT", label: "Italia", alias: ["Italy", "Italien", "Italia"] },
	// { value: "LV", label: "Latvija", alias: ["Latvia", "Lettland"] },
	// { value: "LT", label: "Lietuva", alias: ["Lithuania", "Litauen"] },
	{
		value: "NL",
		label: "Nederland",
		alias: ["Netherlands", "Holland", "Pays-Bas"],
	},
	{ value: "NO", label: "Norge", alias: ["Norway", "Norwegen", "Norvège"] },
	{ value: "PL", label: "Polska", alias: ["Poland", "Polen", "Pologne"] },
	{ value: "PT", label: "Portugal", alias: ["Portugal", "Portogallo"] },
	{ value: "ES", label: "España", alias: ["Spain", "Spanien", "Espagne"] },
	{ value: "SE", label: "Sverige", alias: ["Sweden", "Schweden", "Suède"] },
	// {
	// 	value: "GB",
	// 	label: "United Kingdom",
	// 	alias: ["United Kingdom", "UK", "Britain"],
	// },
];
