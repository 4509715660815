import { useQuery } from "@tanstack/react-query";
import {
	Link,
	useLoaderData,
	useParams,
	useSearch,
} from "@tanstack/react-router";
import { useEffect } from "react";
import { isDesktop } from "react-device-detect";
import QRCode from "react-qr-code";
import { z } from "zod";
import { InflowIcon } from "../assets/inflow";
import { fetcher } from "../lib/fetcher";
import { useCustomer } from "../store/customer";
import { Loading } from "./loading";

export function ConnectBank() {
	const { paymentId } = useParams({ from: "/connect/$paymentId" });
	const { payment } = useLoaderData({ from: "/connect/$paymentId" });
	const { bank_id } = useSearch({ from: "/connect/$paymentId" });
	const { email } = useCustomer();

	const { data, isLoading } = useQuery<{
		linkUrl: string;
	}>({
		queryKey: ["session", email, bank_id, paymentId],
		queryFn: async () =>
			fetcher(
				`${import.meta.env.VITE_API_URL}/checkout/submit/${paymentId}`,
				z.object({
					linkUrl: z.string(),
				}),
				{
					method: "POST",
					body: JSON.stringify({
						email: payment.customerEmail ?? email,
						bankId: bank_id,
					}),
				},
			),
	});

	useEffect(() => {
		if (!isDesktop && !isLoading && data?.linkUrl) {
			window.location.replace(data?.linkUrl);
		}
	}, [data, isLoading]);

	return (
		<div className="w-full flex justify-center xl:justify-start items-center">
			<div className="w-full md:h-[75vh] max-w-lg flex flex-col space-y-4 p-4 xl:ml-4 2xl:ml-16">
				<div>
					<Link
						to="/pay/$paymentId"
						params={{ paymentId }}
						search={{ email: email ?? undefined }}
						className="text-neutral-500 underline"
					>
						Back to bank selection
					</Link>
				</div>
				<div className="p-4 flex flex-col justify-between items-center space-y-4 border border-neutral-300 rounded-md shadow-sm">
					<span className="text-neutral-500 max-w-sm text-center">
						{isDesktop
							? "Scan this QR code with your phone camera to complete the bank login in the app"
							: "Redirecting to bank login..."}
					</span>
					{isDesktop && !isLoading && data ? (
						<>
							<QRCode value={data.linkUrl} className="w-32 h-32" />
							<span className="text-sm text-neutral-500">
								Want the link instead?{" "}
								<a
									href={data?.linkUrl}
									target="_blank"
									rel="noreferrer"
									className="underline disabled:opacity-50"
									aria-disabled={!data?.linkUrl}
								>
									Click here
								</a>
							</span>
						</>
					) : (
						<Loading className="w-32 h-32" />
					)}
				</div>
				<div className="mx-auto flex items-start md:hidden">
					<span className="text-neutral-500 text-xs font-semibold">
						Powered by
					</span>
					<InflowIcon className="w-12 h-3 fill-neutral-500" />
				</div>
			</div>
		</div>
	);
}
