import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createRoute, useParams } from "@tanstack/react-router";
import { z } from "zod";
import { InflowIcon } from "../assets/inflow";
import { BankInfo } from "../components/bank-info";
import { MerchantInfo } from "../components/merchant-info";
import { Message } from "../components/message";
import { fetcher } from "../lib/fetcher";
import { paymentSchema } from "../schemas/payment";
import { queryClient, rootRoute } from "./__root";
import { useCustomer } from "../store/customer";

const paymentRouteSchema = z.object({
	email: z.string().email().optional(),
	error: z.string().optional(),
});

function paymentQueryOptions(paymentId: string) {
	return queryOptions({
		queryKey: ["payment", paymentId],
		queryFn: () =>
			fetcher(
				`${import.meta.env.VITE_API_URL}/checkout/payment/${paymentId}`,
				paymentSchema,
			),
	});
}

export const paymentRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/pay/$paymentId",
	beforeLoad: async ({ search }) => {
		return {
			search,
		};
	},
	loader: async ({ params, context }) => {
		const payment = await queryClient.ensureQueryData(
			paymentQueryOptions(params.paymentId),
		);

		if (payment.customerEmail) {
			useCustomer.getState().setCustomerEmail(payment.customerEmail);
		} else if (context.search.email) {
			useCustomer.getState().setCustomerEmail(context.search.email);
		}

		return {
			payment,
		};
	},
	component: PaymentPage,
	errorComponent: () => (
		<Message
			title="Something went wrong"
			content="The page you were looking for could not be found. Please check the URL or contact the merchant."
		/>
	),
	validateSearch: paymentRouteSchema,
});

function PaymentPage() {
	const { paymentId } = useParams({
		from: "/pay/$paymentId",
	});
	const { data: payment } = useSuspenseQuery(paymentQueryOptions(paymentId));

	if (
		payment.status === "CHECKOUT_SUCCESS" ||
		payment.status === "PAYMENT_RECEIVED" ||
		payment.status === "PAYMENT_SUCCESS"
	) {
		const url = new URL(payment.successUrl);
		const search = new URLSearchParams(url.search);

		search.set("inflow_payment_id", payment.id);

		window.location.replace(
			`${url.origin}${url.pathname}?${search.toString()}`,
		);
	}

	// if (payment.status === "EXPIRED") {
	// 	return (
	// 		<Message
	// 			title="Payment expired"
	// 			content="The payment has expired. Please return to the merchant to try again."
	// 			actionLabel="Go back to the merchant"
	// 			actionLink={payment.cancelUrl}
	// 		/>
	// 	);
	// }

	return (
		<div className="w-screen h-screen relative md:h-screen flex flex-col md:flex-row items-center">
			<MerchantInfo from="/pay/$paymentId" />
			<BankInfo />
			<div className="fixed bottom-0 h-8 bg-white w-full mx-auto flex items-center justify-center md:hidden">
				<div className="flex items-start">
					<span className="text-neutral-500 text-xs font-semibold">
						Powered by
					</span>
					<InflowIcon className="w-12 h-3 fill-neutral-500" />
				</div>
			</div>
		</div>
	);
}
