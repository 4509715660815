import { cn } from "../lib/cn";

type Props = {
	className?: string;
};

export function ErrorMessage({ className }: Props) {
	return (
		<div
			className={cn(
				"w-full h-screen flex flex-col space-y-1 justify-center items-center",
				className,
			)}
		>
			<h1 className="text-neutral-700">Sorry, an error occurred</h1>
			<span className="text-sm text-neutral-500 max-w-sm text-center px-6">
				We've logged the error and we'll look into it. Please try again later.
			</span>
		</div>
	);
}
