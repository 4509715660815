import { create } from "zustand";

type PopupState = {
	pricingDetails: boolean;
	setPricingDetails: (value: boolean) => void;
};

export const usePopupStore = create<PopupState>()((set) => ({
	pricingDetails: false,
	setPricingDetails: (value) => set({ pricingDetails: value }),
}));
