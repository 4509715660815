import { createRouter } from "@tanstack/react-router";
import { rootRoute } from "./pages/__root";
import { linkRoute } from "./pages/link";
import { paymentRoute } from "./pages/pay";
import { connectRoute } from "./pages/connect";
import { loadingRoute } from "./pages/loading";

const routeTree = rootRoute.addChildren([
	paymentRoute,
	connectRoute,
	linkRoute,
	loadingRoute,
]);

export const router = createRouter({ routeTree, defaultPreload: "intent" });

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}
