type Props = {
	title: string;
	content: string;
	actionLabel?: string;
	actionLink?: string;
};

export function Message({ title, content, actionLabel, actionLink }: Props) {
	return (
		<div className="w-full h-screen flex flex-col space-y-1 justify-center items-center">
			<h1 className="text-neutral-700">{title}</h1>
			<span className="text-sm text-neutral-500 max-w-sm text-center px-6">
				{content}
			</span>
			{actionLabel && actionLink && (
				<a
					href={actionLink}
					className="pt-6 text-sm text-neutral-500 underline"
				>
					{actionLabel}
				</a>
			)}
		</div>
	);
}
